@import '../campaign';

.email {
  .body {
    .previewOuter {
      width: 500px;

      .layouts {
        display: flex;
        margin-left: 30px;

        .layoutTile {
          cursor: pointer;
          padding: 3px;
          border: 1px solid #aaa;
          border-radius: 3px;
          font-size: 13px;
          opacity: 0.4;
          text-align: center;

          + .layoutTile {
            margin-left: 4px;
          }

          &.selected {
            font-weight: bold;
            opacity: 1;
            background-color: #f5f5f5;
          }

          img {
            width: 80px;
          }
        }
      }
    }
  }
}

.emailPreviewContainer {
  width: 980px;
  transform: scale(0.465);
  margin: 10px 30px;
  padding: 30px;
  border: 8px inset gray;
  background-color: #999;
  transform-origin: top left;

  &.confirmation {
    max-height: 1200px;
    overflow-y: auto;
  }

  .preview {
    pointer-events: none;
    width: 1200px;
    box-shadow: 12px 12px 5px #666;
    padding-bottom: 25px;
    background-color: #fff;

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: "Source Sans Pro", sans-serif;
    }

    a {
      text-decoration: none;
      cursor: pointer;
    }

    &.layout {
      width: 900px;
      padding: 0;
      margin: 0;
      color: #666;
      background-color: #f7f7f7;
      font-family: AvenirNext, sans-serif;

      p {
        padding: 0;
        margin: 0;
      }

      button {
        background-color: #2196f3;
        padding: 15px 40px;
        border-radius: 5px;
        font-size: 18px;
        color: white;
        margin: 40px;
      }

      .label {
        font-weight: bold;
        text-align: right;
        width: 48%;
      }

      .value {
        text-align: left;
      }

      .content {
        width: 80%;
        max-width: 800px;
        margin: 0 auto;
        padding: 30px 0 50px;
      }

      .logo {
        text-align: left;
      }

      .logo2 {
        margin-top: 50px;
      }

      .banner {
        margin-top: 25px;
        margin-bottom: 10px;
        clear: both;

        img {
          max-width: 100%;
        }
      }

      .center {
        text-align: center;
      }

      .sep {
        border-top: 2px solid #ccc;
        margin: 30px auto;
        width: 200px;
      }

      .text {
        margin: 0 30px;
        padding-top: 25px;
        clear: both;
      }

      .top,
      .bottom {
        text-align: center;
        font-size: 20px;

        a {
          color: blue;
          text-decoration: underline;
        }
      }

      .top {
        margin-bottom: 8px;
      }
    }

    &.layout2 {
      .sep {
        width: 100%;
        margin: 30px;
      }

      .text {
        column-count: 2;
        text-align: left;
        column-gap: 50px;
      }
    }

    &.layout3 {
      button {
        background-color: #50e3c2;
      }

      .logo {
        text-align: center;
        margin-bottom: 15px;
      }

      .banner img {
        margin-top: 0;
      }
    }

    &.layout4 {
      .logo {
        margin-bottom: 15px;
      }

      .logo2 {
        margin-top: 0;
      }
    }

    &.layout5 {
      .logo {
        float: left;
      }

      .logo2 {
        margin-top: 0;
      }

      .title {
        padding-top: 15px;
      }

      .banner img {
        margin-top: 20px;
      }
    }
  }
}
