@import '~app/mixins';

.campaign {
  width: 100%;

  .btn {
    min-width: 100px;

    + .btn {
      margin-left: 10px;
    }
  }

  .header,
  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .left {
    width: 290px;
  }

  .header {
    justify-content: space-between;
    margin: 15px;

    .title {
      color: $color-blue-dark;
      font-size: 24px;
      line-height: 32px;
      display: flex;
    }

    .name {
      color: $color-grey;
      font-style: italic;
    }

    .websiteStatus {
      color: #999;
      margin-top: -10px;
    }

    .url {
      font-size: 13px;
      color: $color-grey;
      font-style: italic;

      a {
        color: $color-blue-medium;
      }
    }
  }

  .body {
    margin: 15px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    display: flex;

    .entry {
      width: 525px;
      min-width: 525px;

      .raisedPanel {
        box-shadow: 2px 2px 5px grey;
        padding: 6px 10px;
        margin: 1px 0 0 1px;
      }

      .fieldLabel {
        color: $color-blue-dark;
        font-size: 15px;
      }

      .label {
        color: #66707d;
        font-weight: 700;
        margin: 15px 0 8px;
        padding-bottom: 3px;
        border-bottom: 1px solid #66707d;
      }

      .images {
        display: flex;
        flex-wrap: wrap;
        align-content: start;
        align-items: start;

        .tile {
          width: 125px;
          margin-top: 0;
        }
      }

      .sectionTitle {
        display: flex;
        justify-content: space-between;
        color: $color-grey;
        font-weight: bold;
        margin-bottom: 8px;
        margin-top: 5px;
        padding-bottom: 4px;
        border-bottom: 1px solid $color-grey;
        align-items: flex-end;

        .placeholderDropdown {
          width: 200px;
        }

        .label {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom-style: none;
        }
      }

      .buttons {
        .btn {
          margin: 5px 0 5px 8px;
        }
      }

      .slider {
        margin: 7px;

        :global {
          .rc-slider-track {
            background-color: $color-blue-dark;
          }

          .rc-slider-handle {
            border-color: $color-blue-dark;
          }
        }
      }

      .headerText,
      .bodyText {
        :global {
          .fr-box .fr-view {
            color: #666;
          }
        }
      }

      .headerText {
        :global {
          .fr-box .fr-view,
          .fr-box .fr-placeholder {
            //font-size: 44px !important;
          }
        }
      }

      .bodyText {
        :global {
          .fr-box .fr-view,
          .fr-box .fr-placeholder {
            //font-size: 18px !important;
          }
        }
      }
    }

    .previewOuter {
      width: 500px;

      .previewContainer {
        margin: 10px 30px;
        padding: 30px;
        border: 8px inset gray;
        background-color: #999;
        transform: scale(0.35);
        transform-origin: top left;
        width: 1280px;

        .preview {
          pointer-events: none;
          width: 1200px;
          box-shadow: 12px 12px 5px #666;
          padding-bottom: 25px;
          background-color: #fff;

          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            font-family: "Source Sans Pro", sans-serif;
          }

          a {
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.popup {
  z-index: 500;
}

.settings {
  .options {
    margin-top: 15px;

    .option {
      margin-bottom: 20px;
    }

    .inlineRadio {
      display: flex;

      > div {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .time {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;

    > div {
      width: 32%;
    }
  }

  .radioButtons {
    display: flex;
    margin-bottom: 5px;

    > div {
      margin-right: 30px;
    }

    .url {
      font-size: 13px;
      font-style: italic;
      color: $color-grey;
    }
  }

  .subContainer {
    display: flex;
    justify-content: space-between;
    height: 25px;

    .sub {
      font-size: 13px;
      font-style: italic;
      color: $color-grey;
      margin-top: 10px;
    }
  }

  .errors {
    margin-top: 20px;
    color: $color-red;
    font-size: 13px;

    ul {
      padding-left: 20px;
    }
  }

  .templateWarning {
    color: red;
    font-size: 13px;
    font-style: italic;
    margin: 5px 25px;
  }

  .buttons {
    text-align: center;
  }

  button {
    width: 100px;
    margin: 0 8px;
  }

  .items {
    display: flex;
    margin: 10px 0 20px;
    overflow-x: auto;
  }

  .item {
    cursor: pointer;
    padding: 5px;
    border: 2px solid transparent;
    border-radius: 5px;

    + .item {
      margin-left: 10px;
    }

    .itemCaption {
      margin-top: 8px;
      color: $color-grey;
      font-size: 13px;
    }

    .template {
      width: 180px;
    }

    img {
      display: block;
      max-width: 100%;
    }

    &.selected {
      @include primary-border-color();

      .itemCaption {
        font-weight: bold;
      }
    }
  }

  .status {
    display: flex;
    align-items: center;
    margin-top: 11px;

    &Icon {
      $size: 18px;

      width: $size;
      height: $size;
      margin-right: 11px;
    }

    &Text {
      font-size: 12px;
      font-style: italic;
      color: $color-green-bright;
    }

    &Wrong {
      color: $color-red;
    }
  }
}
